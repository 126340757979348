import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import * as AuthRole from "./auth.role";
import { ToastrService } from "ngx-toastr";
import { PlatformLocation } from "@angular/common";
import { LOCAL_FIELD } from "../common/constants/localstorage";


export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  const router = inject(Router);
  const toastrService = inject(ToastrService);
  const platformLocation = inject(PlatformLocation);
  const PATHNAME = platformLocation.pathname;
  const roles = route.data['roles'] as Array<string>;

  if (roles === null || roles === undefined || roles.length === 0) {
    return true;
  }
  if (AuthRole.isAuthenticated()) {
    const userInfo = AuthRole.getUserInfo();
    const check = AuthRole.IsInRole(userInfo, roles);
    if (!check) {
      toastrService.error('Bạn không có quyền truy cập vào khu vực này');
      // alert('Bạn không có quyền truy cập vào khu vực này');
      if (router.url === '/login') {
        AuthRole.logOut();
      }
      router.navigateByUrl('/');
    }
    return check;
  } else {
    if (PATHNAME.indexOf('print-data') !== -1) {
      router.navigate(['/login'], { queryParams: { back: PATHNAME } })
    } else {
      if (window.location.pathname !== '/') {
        localStorage.setItem(LOCAL_FIELD.LAST_URL, window.location.href);
      }
      router.navigateByUrl('/login');
    }
    return false;
  }
}

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);